:root{
    --lightgray : rgb(232,235,239);
}
.cl-lightgray{
    color: var(--lightgray);
}
.bg-lightgray{
    background-color: var(--lightgray);
}
.css-qeglhl, .css-13dpel6{
    width: auto !important;
}
/* .ql-container .ql-snow{
    height: 150px !important;
} */

.ql-editor{
    height: 150px !important;
}
.fs-10{
    font-size: 10px !important;
}
.fs-12{
    font-size: 12px !important;
}
.fs-14{
    font-size: 14px !important;
}

a{
    color: black;
    text-decoration: none;
}
a:hover{
    color: black;
    text-decoration: none;
}
.list-style-none{
    list-style-type: none;
}
.swal2-container{
    z-index: 9999 !important;
}
.status-circle{
    padding: 7px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;

}
.w-100px{
    width: 100px ;
}
.w-max-content{
    min-width: max-content;
}
.subcategory-wrap{
    overflow: scroll;
    overflow-x: scroll;
}


/* Modal transition */
.modal-transition-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .modal-transition-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .modal-transition-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .modal-transition-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

    
 .borderNotLast{
    border-left: 1px dashed ;  
  }
  
 .borderNotLast:last-child{
    border-left: none;  
  }
  .borderNotLast .dot{
    border-radius: 4px ;
    padding: 4px;
    background-color: red;
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate(-50%, 0%);
  }
  .borderNotLast .orderStatus{
    position: relative;
    top: -6px;
  }
  